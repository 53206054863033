<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="background-image: url(../assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">FAQ’S</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                FAQ’S
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-3">
            <div class="card shadow-none bg-transparent">
              <h4
                class="card-header font-weight-bold bg-success rounded-top text-white"
              >
                問題分類
              </h4>
              <div class="card-body border border-top-0 rounded-bottom">
                <ul class="list-unstyled mb-0">
                  <li class="mb-2">
                    <router-link to="/faq/register"
                      class="text-muted  font-weight-medium d-block border rounded py-2 pl-3"
                      >
                      會員註冊相關問題
                    </router-link>
                  </li>
                  <li class="my-2">
                    <router-link to="/faq/game-rule"
                      class="font-weight-medium d-block border rounded py-2 pl-3"
                      >
                    遊戲規則說明
                    </router-link>
                  </li>
                  <li class="my-2">
                    <router-link to="/faq/point-exchange"
                      class="text-muted font-weight-medium d-block border rounded py-2 pl-3"
                      >
                      點數及兌換機制說明
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-lg-9 order-md-1">
            <div class="accordion" id="accordionOne">
              <div class="card">
                <div class="card-header bg-warning" id="headingOne">
                  <h5
                    class="icon-bg"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <span>i玩錢分級制度說明</span>
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show shadow-sm rounded-sm"
                  aria-labelledby="headingOne"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <p class="mb-6">
                      i玩錢將針對用戶財商年齡進行分級（非實際的年齡），分級是以「財商」年齡，並非以實際年齡分級，我們將財商年齡分為「幼幼級」、「國小級」、「國中級」及「高中級」，每一個等級再區分五個級別從Level 1~Level 5，當你答對題目就可以晉升到下一個Level。
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-success" id="headingTwo">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span>i玩錢遊戲規則題庫說明</span>
                  </h5>
                </div>

                <div
                  id="collapseTwo"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <p class="mb-6">
                      i玩錢一共有1500個關於財商內容的遊戲問題，遊戲問題形式分為「文字選擇題」、「圖文選擇題」、「影片選擇題」，測驗的內容包括「認識錢」、「記帳」、「需要跟想要」、「零用錢」、「預算」、「收入及支出」、「認識貨幣」、「外匯及換匯」、「銀行及開戶」、「利息及複利」、「股票、基金、ETF」、「保險」、「信用理財及理債」等等。
                    </p>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-danger" id="headingThree">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <span>如何下載「練習單」</span>
                  </h5>
                </div>

                <div
                  id="collapseThree"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingThree"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <p class="mb-6">
                      i玩錢針對用戶財商年齡進行分級，分為「幼幼級」、「國小級」、「國中級」及「高中級」，每一個等級我們將提供父母可下載的練習單（Jpg 或PDF），讓父母透過練習單陪小孩一起學理財。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'game-rule',
  mounted () {
    require('@/assets/js/base.js')
  }
}
</script>
